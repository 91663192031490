


































































































































































































































































































































































/* Import shared variables if available */
/* @import '@/styles/_variables.scss'; */

/* All common styles (.filter-section, .filter-group, .filter-group-title, */
/* .checkbox-grid, .checkbox-item, .vanilla-checkbox, .checkbox-label, */
/* .loading-placeholder) are now defined in the UNSCOPED <style> block */
/* in Finder-Base.vue */

/* Add any styles SPECIFIC to First.vue here */

.premium-indicator {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    font-weight: bold;
    color: #FFC107; /* Gold color */
    background-color: rgba(255, 193, 7, 0.15);
    border: 1px solid rgba(255, 193, 7, 0.5);
    border-radius: 4px;
    padding: 1px 4px;
    line-height: 1;
    margin-left: 4px;
}

/* Keep specific grid adjustments if they were needed */
.rentabilidad-grid {
     /* grid-template-columns: repeat(auto-fill, minmax(100px, 1fr)); */ /* Example if needed */
}

/* Keep premium link styling if the link might reappear based on logic */
/*
.premium-link {
    font-size: 11px;
    font-weight: 600;
    color: var(--primary, #00aedd);
    text-decoration: none;
    white-space: nowrap;
    margin-left: 10px; 
    transition: color 0.2s ease;
    place-self: center; 
}
.premium-link:hover {
    color: var(--primary-dark, #007A9E);
    text-decoration: underline;
}
*/

