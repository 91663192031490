





















































































































































































































































































































































































@use "@material/checkbox"; 
  @use "@material/icon-button"; 
  @use "@material/data-table/data-table";

  @include checkbox.core-styles;
  @include icon-button.core-styles;
  @include data-table.core-styles;
  @include data-table.theme-baseline;

  $color_dark: #000010;
  $back_white: #FFFFFF;
  $back_light_grey: #F3F4F7;

  .mdc-data-table__header-cell {
    text-transform: uppercase;
    background-color: $back_light_grey;}
  .mdc-data-table__header-cell,
  .mdc-data-table__cell{
    color: $color_dark;
    font-size: 12px;
    letter-spacing: 0px;
    text-align: center;}
  .mdc-data-table__row {
    background-color: $back_white;}
  .index-content {
    display: flex;
    justify-content: center;
    flex-direction: column;}
  p {
    margin: 0;}
  .mdc-data-table {
    margin-bottom: 40px;
    width: 100%;}
  .options {
    cursor: pointer;
  }

  .table-custom{
    height: 87vh;
    background-color: #F3F4F7;
    overflow-y: scroll;
    border: 1px solid lightgrey;
    border-radius: 5px;
    width: 97.5%;
  }

  .full-table{
    width: 100%;
    width: -moz-available;          /* WebKit-based browsers will ignore this. */
    width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
    width: fill-available;
  }
  .top-table{
    width: 100%;
  }
  #stock-table-custom{
    span{
      font-size: 13px;
      font-family: "Open sans";
    }
    .material-icons{
      font-size: 20px;
      font-family: 'Material Icons Outlined';
    }
    .material-icons-outlined{
      font-size: 20px;
      font-family: 'Material Icons Outlined';
    }
    .mdc-data-table__cell {
      width: 20px;
      min-width: 20px;
      span{
        font-size: 12px;
      }
      .material-icons {
        font-size: 15px;
      }
      .material-icons-outlined {
        font-size: 15px;
      }
    }
    .mdc-data-table__header-cell {
      width: 20px;
      min-width: 20px;
      padding: 0;
      margin: 0;
      .mdc-data-table__header-cell-label{
        font-size: 10px;
      }
      .mdc-data-table__sort-icon-button{
        font-size: 13px;
        width: 10px;
      }
    }

    .row-grey, .row-default{
      cursor: pointer !important;
    }

    .row-grey{
      background-color: var(--color-custom-table-grey);
    }

    .row-default{
      background-color: var(--color-custom-table-default);
    }

    .row-grey:hover{
      background-color: var(--color-custom-table-hover) !important;
    }

    .row-default:hover{
      background-color: var(--color-custom-table-hover) !important;
    }

    .row-grey *, .row-default * {
      color: var(--color-typography-on-background) !important;
    }
  }

  .mdc-data-table__header-cell {
    background-color: var(--color-custom-table-titles) !important;
    color: var(--color-typography-on-background) !important;
  }

  .mdc-data-table__header-cell * {
    color: var(--color-typography-on-background) !important;
  }

  .mdc-selected-row {
    background-color: var(--color-custom-table-selected) !important;
  }
