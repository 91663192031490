




































































































































































































































































































































































































/* Styles inherited from Finder-Base.vue unscoped block */
/* No need to redefine .filter-group-title, .action-btn, .secondary-btn here */

/* Specific layout for the Fourth filter section */
.fourth-group {
    /* This is the main container within the .filter-section provided by Finder-Base */
    /* No specific styles needed unless overriding section padding/margin */
    display: flex;        /* Use flexbox for the groups within this column */
    flex-direction: column;
    gap: 20px;            /* Use the standard gap between filter groups */
}

/* Remove .filter-sub-group styles as we now use .filter-group */
/* 
.filter-sub-group {
    border: 1px solid var(--color-outline-variant, #e0e0e0); 
    border-radius: 6px;
    padding: 12px 16px;
    margin-bottom: 15px; 
    background-color: var(--color-surface, #F3F4F7); 
}
.filter-sub-group:last-child {
    margin-bottom: 0;
}
.filter-sub-group .filter-group-title {
     border-bottom: none; 
     padding-bottom: 0;
     margin-bottom: 8px;
     font-size: 13px; 
     font-weight: 600;
     color: var(--color-text-primary); 
}
*/

/* Styles for elements within the .filter-group */

/* Style the button used to open the modal */
.select-btn {
    /* Inherits base .action-btn styles */
    /* Apply .secondary-btn styling - make it match the dark buttons in other components */
    background-color: var(--color-muted, #30303d); /* Dark background to match other buttons */
    color: var(--color-text-primary, white); /* Light text for contrast */
    border: none; /* No border on dark buttons */
    font-size: 13px;
    height: 36px; /* Match input height */
    padding: 0 12px; /* Adjust padding */
    width: 100%; /* Make button full width of its container */
    margin-top: 10px; /* Space above button - Use standard gap */
    text-transform: none; /* Override uppercase from action-btn */
    justify-content: center; /* Center content */
    cursor: pointer; /* Add pointer cursor to indicate it's clickable */
    border-radius: 4px; /* Add rounded corners for consistency */
    display: flex; /* Ensure flexbox is used for alignment */
    align-items: center; /* Properly center the icon and text vertically */
}
.select-btn:hover:not(:disabled) {
    background-color: var(--color-active-surface, #3f3f4e); /* Slightly lighter on hover */
    border-color: transparent;
}
.select-btn:disabled {
    /* Inherits disabled style from .action-btn */
    opacity: 0.6;
    cursor: not-allowed; /* Change cursor for disabled state */
}
.select-btn i {
    font-size: 18px;
    margin-right: 6px; /* Ensure gap from base style is applied */
    line-height: 1; /* Ensure icon proper alignment */
}

/* Display area for selected items */
.selected-items-display {
    min-height: 36px; /* Match button/input height */
    margin-bottom: 0; /* Display area is directly above button now, use button margin */
    padding: 6px 10px; /* Match input padding */
    background-color: var(--color-background, white); /* Match input background */
    border-radius: 4px; /* Match input border-radius */
    border: 1px solid var(--color-outline, rgba(0, 0, 0, 0.12)); /* Match input border */
    display: flex;
    flex-wrap: wrap;
    align-items: center; /* Vertically align items */
    gap: 4px 6px; /* Row and column gap */
    font-size: 13px; /* Match input font size */
    color: var(--color-text-secondary);
    cursor: default; /* Indicate it's not directly editable */
    overflow: hidden; /* Prevent overflow */
}

.no-selection {
    font-style: italic;
    opacity: 0.7;
    line-height: 1.5; /* Adjust line height */
    padding: 1px 0; /* Add minimal padding to better align vertically with tags */
}

.selected-tag {
    background-color: var(--color-active-surface, #e0e0e0); /* Use active surface color */
    color: var(--color-text-primary);
    padding: 3px 8px; /* Adjust padding */
    border-radius: 12px; /* Pill shape */
    white-space: nowrap;
    font-weight: 500;
    font-size: 12px; /* Slightly smaller */
    line-height: 1.4;
    max-width: 150px; /* Limit width to prevent overflow */
    overflow: hidden; /* Hide overflow */
    text-overflow: ellipsis; /* Add ellipsis for text truncation */
    display: inline-block; /* Necessary for text-overflow to work */
}

/* Loading placeholder - inherits from unscoped styles */
.loading-placeholder {
    /* Styles defined in Finder-Base.vue */
}

