
































































































































































































































































































































































































































































































































































/* Styles inherited from Finder-Base.vue unscoped block */

/* Add any styles SPECIFIC to Third.vue here */

.third-filters-container {
    /* Container specific adjustments if needed */
}

.filter-row {
    display: flex;
    flex-wrap: wrap; /* Allow wrapping within a row */
    gap: 20px; /* Space between groups in the same row */
    margin-bottom: 20px; /* Space between rows */
}

.filter-row:last-child {
    margin-bottom: 0;
}

/* Adjust flex basis for groups if needed */
.filter-group {
     flex: 1 1 200px; /* Example: allow growing/shrinking with a base width */
     min-width: 180px;
}

/* Make CFD select potentially wider */
.cfd-select {
    /* Inherits width: 100% */
}

/* Style for text date inputs */
.date-text-input {
    /* Inherits .vanilla-input styles */
}

.date-text-input.invalid-date {
    border-color: var(--color-error, #B00020);
    box-shadow: 0 0 0 2px rgba(176, 0, 32, 0.2);
}

/* Wrapper for date text input */
.date-input-wrapper {
    /* Inherits .input-wrapper styles */
}


/* Remove old vuejs-datepicker styles */
/*
.datepick ::v-deep .datepicker-input { ... }
.datepick ::v-deep .vdp-datepicker__calendar { ... }
*/

