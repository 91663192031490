



























































































































































































/* Import shared variables if possible */
/* @import '@/styles/_variables.scss'; */

.modal-fade-enter-active, .modal-fade-leave-active {
  transition: opacity 0.3s ease;
}
.modal-fade-enter, .modal-fade-leave-to {
  opacity: 0;
}

.modal-overlay.multi-select-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(15, 15, 26, 0.6); /* Slightly darker overlay */
  display: flex;
  justify-content: center;
  align-items: center; /* Center vertically */
  z-index: 1060; /* Higher z-index if needed */
  padding: 20px;
}

.modal-container.multi-select-modal {
  background-color: var(--color-surface);
  border-radius: 8px;
  width: 90%;
  max-width: 550px; /* Adjust width as needed */
  max-height: 85vh; 
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-shadow: 0 5px 25px rgba(15, 15, 26, 0.2);
  color: var(--color-text-primary);
}

/* Modal Header */
.modal-header {
  padding: 16px 24px;
  border-bottom: 1px solid var(--color-outline);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
}

.title-wrapper {
  display: flex;
  align-items: center;
  gap: 12px;
}

.header-icon {
  color: var(--primary);
  font-size: 22px;
}

.modal-title {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
}

.modal-close-btn {
  background: none;
  border: none;
  cursor: pointer;
  color: var(--color-text-secondary);
  padding: 4px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  transition: background-color 0.2s ease-in-out;
}
.modal-close-btn:hover {
  background-color: var(--color-active-surface);
}
.modal-close-btn i {
  font-size: 20px;
}

/* Modal Body */
.modal-body.multi-select-body {
  display: flex;
  flex-direction: column;
  overflow: hidden; 
  flex-grow: 1;
  padding: 16px 24px; 
}

.search-input-wrapper {
    position: relative;
    margin-bottom: 16px;
    flex-shrink: 0;
}

.search-icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: var(--color-text-secondary);
    font-size: 20px;
    pointer-events: none; /* Don't intercept clicks */
}

.search-input {
    padding-left: 40px; /* Space for icon */
    padding-right: 40px; /* Space for clear button */
}

.clear-search-btn {
     position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    color: var(--color-text-secondary);
    cursor: pointer;
    padding: 5px;
     display: flex;
    align-items: center;
    justify-content: center;
}
.clear-search-btn i {
    font-size: 18px;
}
.clear-search-btn:hover {
     color: var(--color-text-primary);
}

.options-list-container {
    flex-grow: 1;
    overflow-y: auto; /* Enable scrolling for the list */
    border-radius: 4px;
    margin: 0 -8px; /* Negative margin to align with padding */
    padding: 8px 8px 0 8px; /* Add padding back */
}

.options-list {
    display: flex;
    flex-direction: column;
    gap: 4px; /* Space between options */
}

.option-item {
    padding: 6px 8px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.15s ease;
}
.option-item:hover {
     background-color: var(--color-active-surface);
}

.option-item .vanilla-checkbox {
     width: 16px; /* Slightly smaller */
     height: 16px;
     margin-right: 10px; 
}

.option-item .vanilla-checkbox::before {
    font-size: 10px; /* Smaller checkmark */
}

.option-label {
    font-size: 14px;
    line-height: 1.3;
    flex-grow: 1; /* Allow label to take space */
}

.no-options-message {
    padding: 20px;
    text-align: center;
    color: var(--color-text-secondary);
    font-style: italic;
}


/* Modal Footer */
.modal-footer {
  padding: 12px 24px;
  border-top: 1px solid var(--color-outline);
  display: flex;
  justify-content: space-between; /* Space out clear and action buttons */
  align-items: center;
  flex-shrink: 0;
}

.footer-actions {
    display: flex;
    gap: 10px; /* Space between Cancel and Apply */
}

.clear-all-btn {
     background-color: transparent;
     color: var(--color-text-secondary);
     padding-left: 10px;
     padding-right: 12px;
     border: 1px solid transparent; /* No border initially */
}
.clear-all-btn:hover:not(:disabled) {
     background-color: var(--color-active-surface);
     color: var(--color-error);
     border-color: var(--color-outline);
}
.clear-all-btn i {
    color: var(--color-error);
    font-size: 20px; /* Larger icon */
    margin-right: 4px;
}

/* --- Copied Base Styles --- */

/* Base Action Button Styles */
.action-btn {
  padding: 10px 16px; /* Adjusted padding slightly */
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  display: inline-flex; /* Use flex for alignment */
  align-items: center; /* Vertically center content */
  justify-content: center; /* Horizontally center content */
  gap: 6px; /* Space between icon and text */
  line-height: 1.2; /* Prevent text jumping */
}

.action-btn i.material-icons {
    font-size: 18px; /* Adjust icon size if needed */
    margin: 0; /* Reset any potential margins */
}

.primary-btn {
  background-color: var(--primary);
  color: var(--color-primary);
}

.primary-btn:hover {
  background-color: var(--color-highlight);
}

.secondary-btn {
  background-color: var(--color-muted);
  color: var(--color-text-primary);
}

.secondary-btn:hover {
  background-color: var(--color-active-surface);
}

.clear-all-btn {
  padding-left: 10px; /* Keep specific padding if needed */
  padding-right: 12px;
}

.clear-all-btn:hover:not(:disabled) {
  color: var(--color-error);
}

.clear-all-btn i {
  color: var(--color-error);
}

.action-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
