














































































@use "@material/floating-label/mdc-floating-label";
@use "@material/line-ripple/mdc-line-ripple";
@use "@material/notched-outline/mdc-notched-outline";
@use "@material/textfield";
@include textfield.core-styles;

.digit-outline-filters {
    --digitinput-border-width: 1px;
    --digitinput-border-color: rgba(0, 0, 0, 0.1);
}

.digit-outline-filters.mdc-text-field {
    @include textfield.disabled-fill-color(#F3F4F7);
    @include textfield.ink-color(var(--color-custom-filter-inputs-font-color));
    @include textfield.label-color(var(--color-custom-filter-inputs-font-color)); //color del label que esta en el input y que luego se pone arriba
    @include textfield.placeholder-color(rgba(0, 0, 0, 0.6));
    @include textfield.disabled-ink-color(rgba(0, 0, 0, 0.6));
    @include textfield.outline-color(#c7c8cc);//color del borde cuando no esta seleccionado
    @include textfield.outlined-height(50px, 50px);
    //@include textfield.fill-color(var(--color-background-background-casillas));
    --mdc-theme-primary: rgba(0, 0, 0, 0.6); //con esta linea cambiamos el borde del input cuando esta seleccionado.
    @include textfield.hover-outline-color(#343948);
    @include textfield.focused-outline-color(#343948);
}

.digit-outline-filters .mdc-input-decorations > span {
    border-width: var(--digitinput-border-width) !important;
    //background-color: var() !important;
}

.digit-outline-filters .mdc-input-decorations .notch {
    border-left: none !important;
    border-right: none !important;
}

.digit-outline-filters input {
    z-index: 5;
}

