


























































































































































































































































































































































































@use "@material/list";

@include list.core-styles;
@include list.item-selected-text-color(black);

.mdc-list-item {
  @include list.item-primary-text-ink-color(#444);
  @include list.list-item-height(36px);
  border-bottom: 1px solid #e9e9e9;
}

:not(.mdc-list-item--disabled).mdc-list-item--selected .mdc-list-item__ripple[data-v-1d7efbc2]::before {
    background-color: var(--mdc-ripple-color, var(--mdc-theme-primary, red));
}

li.mdc-list-item {display:flex;}
li span.mdc-list-item__meta.material-icons {margin:unset; margin-left: 4px}
li span.mdc-list-item__meta.material-icons.push {margin-left: auto;}

.mdc-list-item.mdc-list-item--selected::before {
  display:none;
}

li.mdc-list-item--selected .material-icons {
  &.alarm:hover {color:green}
  &.card-travel:hover {color:orange;}
  &.favorite:hover {color:#00add8;}
}

li.mdc-list-item .material-icons {
  &.alarm.active {color:green}
  &.card-travel.active {color:orange;}
  &.favorite.active {color:#00add8;}
}

.ekers-event-click {
  cursor:pointer;
}

.mdc-data-table {
    width: 97.5%;
    margin-left: 0.4%;
    margin-bottom: 1.5%;
    height: 135%;
}

.blured{
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
  background-color: white;
}

