














































































































































































































































































































































































.stockchart-chart {
    height: calc(89vh);
    position:relative;
    overflow:hidden;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
    background-color: var(--color-custom-stockchart-background);
}

@media screen and (max-height: 800px) {
  .stockchart-chart{
    margin-right: 21px;
  }
}

.stockchart{
    height: 100%;
    overflow:hidden;
}
#iframe, #mobile-iframe {
    position: relative;
    z-index: 2;
    transition: opacity 0.5s ease-in-out;
}

/* Iframe loading/loaded states for fade effect */
.iframe-loading {
  opacity: 0;
}

.iframe-loaded {
  opacity: 1;
}

.title-searches{
    background-color: var(--color-custom-search-bar);
    text-align: center;
    height: 40px;
    margin-left: 82px !important;
    margin-right: 82px !important;
  }

  .close-searches{
    font-size: 16px;
    right: 30px;
    position: relative;
    margin-top: 10px;
    color: white;
    float: right;
  }

  .search-text{
    margin-top: 30px !important;
    line-height: 40px !important;
    color: white;
  }

  .chart-with-search-tittle{
    height: 94% !important;
  }

  #fullscreen-button-container-all-pages{
    display: none;
  }
  .banner-big-bank{
    position: absolute;
    width: 20%;
    float: right;
    z-index: 3;
    right: 7%;
  }

  .iframe-loading-indicator {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--color-custom-stockchart-background);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }

  .wave {
    width: 5px;
    height: 100px;
    background: linear-gradient(45deg, var(--primary-light, #4E6E82), var(--primary, #245275));
    margin: 10px;
    animation: wave 1s ease-in-out infinite;
    border-radius: 20px;
  }

  .wave:nth-child(2) {
    animation-delay: 0.1s;
  }

  .wave:nth-child(3) {
    animation-delay: 0.2s;
  }

  @keyframes wave {
    0% {
      transform: scale(0.3);
    }
    50% {
      transform: scale(1);
    }
    100% {
      transform: scale(0.3);
    }
  }

  .full-screen-loader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    background-color: var(--color-background, #fff);
    display: flex;
    justify-content: center;
    align-items: center;
  }
