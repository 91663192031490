
























































#stocks{
  .table-custom{
    height: auto;
    width:auto;
    margin-top:25px;
    margin-bottom:50px;
  }
  .header-table{
    margin-right:50px !important;
  }
}

#best-bullish-values{
  .header-table{
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    width: 208.5px;
    height: 40px;
  }
  .table-custom{
    height: 220px;
    width: auto;
    background-color: #F3F4F7;
    overflow-y: scroll;
    border: 1px solid lightgrey;
    border-radius: 5px;
  }

  .table-row{
    font-size: 12px;
    height: 40px;
    background-color: white;
    font-weight: normal;
  }
  .top-table{
    width: 100%;
  }
}

#hot-values{
  .header-table{
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    width: 208.5px;
    height: 40px;
  }
  .table-custom{
    height: 220px;
    width: auto;
    background-color: #F3F4F7;
    overflow-y: scroll;
    border: 1px solid lightgrey;
    border-radius: 5px;
  }

  .table-row{
    font-size: 12px;
    height: 40px;
    background-color: white;
    font-weight: normal;
  }
  .top-table{
    width: 100%;
  }
}

#best-sectors{
  .header-table{
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    width: 208.5px;
    height: 40px;
  }
  .table-custom{
    height: 220px;
    width: auto;
    background-color: #F3F4F7;
    overflow-y: scroll;
    border: 1px solid lightgrey;
    border-radius: 5px;
  }

  .table-row{
    font-size: 12px;
    height: 40px;
    background-color: white;
    font-weight: normal;
  }
  .top-table{
    width: 100%;
  }
}

#portfolio-changes{
  .header-table{
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    width: auto;
    height: 40px;
  }
  .table-custom{
    height: 220px;
    width: auto;
    background-color: #F3F4F7;
    overflow-y: scroll;
    border: 1px solid lightgrey;
    border-radius: 5px;
  }

  .table-row{
    font-size: 12px;
    height: 40px;
    background-color: white;
    font-weight: normal;
  }
  .top-table{
    width: 100%;
  }
}

#twin-souls{
  .header-table{
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    width: 417px;
    height: 40px;
  }
  .table-custom{
    height: 180px;
    width: auto;
    background-color: #F3F4F7;
    overflow-y: scroll;
    border: 1px solid lightgrey;
    border-radius: 5px;
  }

  .table-row{
    font-size: 12px;
    height: 40px;
    background-color: white;
    font-weight: normal;
  }
  .top-table{
    width: 100%;
  }
}

#artificial-inteligence{
  .header-table{
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    width: 139px;
    height: 40px;
  }
  .table-custom{
    height: 220px;
    width: auto;
    background-color: #F3F4F7;
    overflow-y: scroll;
    border: 1px solid lightgrey;
    border-radius: 5px;
  }

  .table-row{
    font-size: 12px;
    height: 40px;
    background-color: white;
    font-weight: normal;
  }
  .top-table{
    width: 100%;
  }
}

#waiting-values{
  .header-table{
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    width: 104px;
    height: 40px;
  }
  .table-custom{
    height: 220px;
    width: auto;
    background-color: #F3F4F7;
    overflow-y: scroll;
    border: 1px solid lightgrey;
    border-radius: 5px;
  }

  .table-row{
    font-size: 12px;
    height: 40px;
    background-color: white;
    font-weight: normal;
  }
  .top-table{
    width: 100%;
  }
}
#last-ipos{
  .header-table{
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    width: 104px;
    height: 40px;
  }
  .table-custom{
    height: 280px;
    width: auto;
    background-color: #F3F4F7;
    overflow-y: scroll;
    border: 1px solid lightgrey;
    border-radius: 5px;
  }

  .table-row{
    font-size: 12px;
    height: 40px;
    background-color: white;
    font-weight: normal;
  }
  .top-table{
    width: 100%;
  }
}

#stock-table{
  .header-table{
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    height: 40px;
  }
  .table-custom{
    height: 90vh;
    background-color: #F3F4F7;
    overflow-y: scroll;
    border: 1px solid lightgrey;
    border-radius: 5px;
    width: 97.5%;
  }

  .table-row{
    font-size: 12px;
    height: 40px;
    background-color: white;
    font-weight: normal;
    width: auto;
  }
  .top-table{
  }
  .full-table{
    width: -webkit-fill-available;
  }
}


