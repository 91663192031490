

















































































































































































































































































































































































































































































































































































































































/* Import shared variables/mixins if available */
/* @import '@/styles/variables.scss'; */

/* Modal Overlay */
.modal-overlay.finder-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(15, 15, 26, 0.5); /* Darker overlay */
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: flex-start; /* Align to top */
  z-index: 1050; /* Ensure it's above other elements */
  padding-top: 5vh; /* Add some top padding */
}

/* Modal Container */
.modal-container.finder-modal {
  background-color: var(--color-background, white);
  border-radius: 8px;
  width: 95%; /* Use more width */
  max-width: 1200px; /* Increase max-width significantly */
  max-height: 90vh; /* Limit height */
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-shadow: 0 5px 25px rgba(15, 15, 26, 0.2);
  color: var(--color-text-primary, #0F0F1A);
}

/* Modal Header */
.modal-header {
  padding: 16px 24px;
  border-bottom: 1px solid var(--color-outline, rgba(0, 0, 0, 0.12));
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
}

.title-wrapper {
  display: flex;
  align-items: center;
  gap: 12px;
}

.header-icon {
  color: var(--primary, #00AEDD);
  font-size: 24px;
}

.modal-title {
  margin: 0;
  font-size: 18px;
            font-weight: 600;
        }

.modal-close-btn {
  background: none;
  border: none;
  cursor: pointer;
  color: var(--color-text-secondary, #606577);
  padding: 4px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  transition: background-color 0.2s ease-in-out;
}

.modal-close-btn:hover {
  background-color: var(--color-surface, #F3F4F7);
}

.modal-close-btn i {
  font-size: 20px;
}

/* Modal Body */
.modal-body.finder-body {
  display: flex;
  flex-direction: column;
  overflow: hidden; /* Prevent body overflow */
  flex-grow: 1;
  padding: 0; /* Remove padding, handle inside sections */
}

/* Top Controls Section */
.finder-top-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
    border-bottom: 1px solid var(--color-outline, rgba(0, 0, 0, 0.12));
    flex-shrink: 0;
    gap: 15px; /* Space between elements */
}

.reset-button {
    padding-left: 12px; /* Adjust padding for icon */
    padding-right: 16px;
}

.reset-button i {
    margin-right: 6px;
    font-size: 20px;
}

.save-search-section {
    display: flex;
    align-items: center;
}

.save-button-container {
   /* No specific styles needed unless for alignment */
}

.save-button {
    padding-left: 12px; /* Adjust padding for icon */
    padding-right: 16px;
}

.save-button i {
    margin-right: 6px;
    font-size: 20px;
}

.disabled-button {
    opacity: 0.6;
    cursor: not-allowed;
}

.add-search-form {
    display: flex;
    align-items: center;
    gap: 10px;
}

/* Reference .vanilla-input from unscoped styles */
.vanilla-input.save-search-input {
    height: 36px; /* Match button height */
    width: 200px; /* Adjust width as needed */
}

.accept-save-button i, .cancel-save-button i {
            font-size: 20px;
}
.accept-save-button span{
    margin-left: 4px;
}

/* Use secondary button style for cancel */
.cancel-save-button {
    background-color: transparent;
    color: var(--color-text-secondary, #606577);
    border: 1px solid var(--color-outline, rgba(0, 0, 0, 0.12));
    padding: 0 8px; /* Smaller padding for icon-only */
    min-width: 36px; /* Ensure circular shape */
}
.cancel-save-button:hover {
     background-color: var(--color-surface, #F3F4F7);
     border-color: var(--color-text-secondary, #606577);
}


/* Filters Content Area */
.filters-content {
    padding: 20px 24px;
    overflow-y: auto; /* Enable vertical scrolling for the whole area if needed */
    flex-grow: 1; /* Take remaining space */
    display: flex; /* Arrange children (First, Second...) horizontally */
    /* flex-wrap: wrap; */ /* Prevent wrapping to enforce horizontal layout */
    gap: 0; /* Remove gap, use border/padding for separation */
}

/* Give each direct child (filter component) a basis and border */
.filters-content > * {
    flex: 1 1 0px; /* Allow shrinking, don't allow growing initially, basis 0 */
    min-width: 200px; /* Minimum width before shrinking */
    border-right: 1px solid var(--color-outline, rgba(0, 0, 0, 0.12)); /* Vertical divider */
    padding: 0 20px; /* Add padding inside each column */
    overflow-y: auto; /* Allow individual columns to scroll if content overflows */
    max-height: calc(90vh - 150px); /* Adjust based on header/footer/padding height */
}

/* First child padding */
.filters-content > *:first-child {
    padding-left: 0;
}

/* Remove border from the last child */
.filters-content > *:last-child {
    border-right: none;
    padding-right: 0;
}


.filter-divider {
    /* This class is now only used *within* child components, not between them */
    /* Styling moved to unscoped style block */
}

/* Reference .loading-filters-placeholder from unscoped */
/* .loading-filters-placeholder { ... } */

/* Crypto Section */
.crypto-section {
    padding: 16px 24px;
    border-top: 1px solid var(--color-outline, rgba(0, 0, 0, 0.12));
    flex-shrink: 0; /* Prevent shrinking */
}

.crypto-toggle-wrapper {
    display: flex;
    justify-content: flex-start; /* Align to the left */
}

.crypto-container {
    display: inline-flex; /* Use inline-flex for alignment */
    align-items: center;
    gap: 12px; /* Space between toggle and text */
        cursor: pointer;
    padding: 4px 8px; /* Add some padding for hover effect */
    border-radius: 18px; /* Match toggle radius */
    transition: background-color 0.2s ease;
}
.crypto-container:not(.disabled-toggle):hover {
    background-color: var(--color-surface, #F3F4F7);
}

.crypto-container.disabled-toggle {
    cursor: not-allowed;
    opacity: 0.6;
}


.crypto-text {
    font-size: 13px;
    font-weight: 600;
    color: var(--color-text-secondary, #606577);
    user-select: none; /* Prevent text selection */
    line-height: 1; /* Align text better with toggle */
}

/* Vanilla Toggle Switch Styles */
/* Moved to unscoped style block */

.crypto-promo {
    text-align: center; 
    padding: 10px 0 0 0;
    font-size: 14px;
    color: var(--color-text-secondary, #606577);
}
.promo-link {
    color: var(--primary, #00aedd);
    text-decoration: none;
    font-weight: 600;
}
.promo-link:hover {
    text-decoration: underline;
}


/* Base Action Button Styles (copied from TickerFinder for consistency) */
.action-btn {
    border: none;
    border-radius: 20px; /* Consistent radius */
    cursor: pointer;
    font-size: 13px; /* Slightly smaller font */
    padding: 0 16px;
    height: 36px;
    transition: all 0.2s ease;
    font-weight: 600;
    display: inline-flex; /* Align icon and text */
    align-items: center;
    justify-content: center;
    gap: 6px; /* Space between icon and text */
    text-transform: uppercase; /* Uppercase text like original */
    letter-spacing: 0.5px;
}
.action-btn i { /* Icon size */
    font-size: 18px; 
}

.primary-btn {
    background-color: var(--primary, #00aedd);
    color: white;
}
.primary-btn:hover:not(:disabled) {
    background-color: #0095c5; /* Darker primary */
}

/* Secondary Button Style (for Reset) */
.secondary-btn {
    background-color: var(--color-surface-variant, #E8EAF6); /* Use a surface variant */
    color: var(--primary-dark, #007A9E); /* Use a darker primary text */
    border: 1px solid var(--color-outline-variant, #C5CAE9); /* Subtle border */
}
.secondary-btn:hover:not(:disabled) {
    background-color: #daddf0; /* Slightly darker surface */
    border-color: #adb5d6;
}

.action-btn:disabled {
    background-color: var(--color-muted, #EAECEF); 
    color: var(--color-text-inactive, rgba(0,0,0,0.4));
    cursor: not-allowed;
    border-color: transparent; /* Remove border for disabled secondary */
}

/* Reference .vanilla-input from unscoped */
/* .vanilla-input { ... } */


/* --- Remove or adapt old styles --- */
/* Remove styles related to the old fixed position sidebar layout */
/* .separator, .stocks-finder, .fullscreen-filters, etc. */
/* Adapt styles for .cripto-container, .reset-button, .save-button-container, etc. */

/* Example of adapting old style */
// .reset-button{ 
    /* OLD Styles */
    /* border-radius: 50px !important; 
    color:white !important;
    background-color: #00aedd;
    border:none !important;
    height:35px !important;
    cursor: pointer !important;
    font-weight: 400 !important;
    letter-spacing: 1px !important;
    font-size: 15px !important;
    margin-top:21px !important;
    margin-bottom: 25px !important;
    padding: 3px 50px !important;
    box-sizing: content-box !important; */
    /* NEW styles are handled by .action-btn.secondary-btn */
// }

/* Remove media queries specific to the old layout if modal handles responsiveness */
/* @media (max-width: 900px){ ... } */
/* @media (min-width: 901px) { ... } */

/* Keep specific adjustments if necessary */
/* Reference #filters-user from unscoped */
/* #filters-user { ... } */

/* Ensure hr is styled consistently */
/* Handled by .filter-divider now in unscoped */

